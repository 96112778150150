import $http from '@/utitls/request'
/**
 * 分页获取个人客户信息
 */
export const GetPersonalCustomPageList = (data) => {
    return $http.get('/api/PersonalCustom/GetPersonalCustomPageList', { params: data })
}
/**
 * 新增
 */
export const AddPersonalCustom = (data) => {
    return $http.post('/api/PersonalCustom/AddPersonalCustom', data)
}
/**
 * 修改
 */
export const EditPersonalCustom = (data) => {
    return $http.put('/api/PersonalCustom/EditPersonalCustom', data)
}
/**
 * 删除
 */
export const DeletePersonalCustom = (data) => {
    return $http.delete('/api/PersonalCustom/DeletePersonalCustom', { params: data })
}