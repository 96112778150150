<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="dialogTitle"
    width="500px"
    @opened="handleOpen"
    @close="handleClose"
    draggable
  >
    <el-form :model="form" label-width="85px" ref="formRef" :rules="rules">
      <el-form-item label="客户名称" prop="Name">
        <el-input v-model="form.Name" />
      </el-form-item>
      <el-form-item label="客户编码" prop="Code">
        <el-input v-model="form.Code" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="form.Phone" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="身份证号">
        <el-input v-model="form.IDCard" autocomplete="off"> </el-input>
      </el-form-item>
      <el-form-item label="住址">
        <el-input v-model="form.Address" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="籍贯">
        <el-input v-model="form.NativePlace" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { AddPersonalCustom, EditPersonalCustom } from '@/api/PersonalCustom'
import { ElMessage } from 'element-plus'
import { isNull } from '@/utitls/validate'
const props = defineProps({
  dialogTitle: {
    type: String,
    default: '',
    required: true
  },
  dialogRowValue: {
    type: Object,
    default: () => {}
  }
})
const formRef = ref(null)
var form = ref({})
const dialogVisible = ref(false)
const rules = reactive({
  Name: [{ required: true, message: '客户名称不能为空', trigger: 'blur' }],
  Code: [{ required: true, message: '客户编码不能为空', trigger: 'blur' }]
})

const emits = defineEmits(['update:modelValue', 'initList'])
const handleOpen = () => {
  if (props.dialogTitle === '修改个人客户信息') {
    form.value = props.dialogRowValue
  }
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  formRef.value.validate(async (valid, fields) => {
    if (valid) {
      if (props.dialogTitle == '添加个人客户') {
        AddPersonalCustom(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
      } else
        EditPersonalCustom(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
    } else {
      console.log('error submit!', fields)
    }
  })
}
</script>
